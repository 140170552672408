<template>
  <div class="wrapper">
    <div class="banner">
      <div class="banner-content">
        <van-swipe :autoplay="30000" indicator-color="white">
          <van-swipe-item v-for="(item, index) in bannerList" :key="index">
            <div class="swiper-item" @click="goUrl(item)">
              <img :src="item.imgUrl" class="img" />
            </div>
          </van-swipe-item>
          <van-swipe-item v-if="!bannerList.length">
            <div class="swiper-item"></div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="main-content">
      <!-- 登录1 -->
      <div class="main-card" v-if="!isLogin">
        <div class="auth-box">
          <div class="remark">您还没有登录，请先登录</div>
          <div class="go-auth" @click="loginDialogShow = true">登录</div>
        </div>
      </div>

      <!-- 登录2-->
      <div class="user-content" v-if="userInfo">
        <div class="user-info main-card">
          <div class="col-info">
            <p class="row-name">
              <span>{{ userInfo.rnName }}</span>
            </p>
            <p class="row-id">{{ userInfo.idCard | formatCardId }}</p>
          </div>
        </div>
        <div class="main-card progress-link" @click="navToApplyList">
          办事进度
        </div>
      </div>

      <!-- 办事项 -->
      <div class="main-card">
        <div class="title">
          <span> 服务事项 </span>
        </div>
        <todoGuide />
      </div>
    </div>
    <LoginDialog :loginDialogShow.sync="loginDialogShow" />
  </div>
</template>

<script>
import { getHomeNewList, getWorkList, getHotWorkList } from "@/api/policy/news";
import { getIndexJobList } from "@/api/job";
import { getfairsList } from "@/api/jobfairs";
import { mapState } from "vuex";
import PolicyIcon from "@/components/PolicyIcon";
import JobList from "@/components/JobList";
import FairList from "@/components/FairList";
import { getPageList } from "@/api/policy/banner";
import { queryAllGroup } from "@/api/policy/itemClassify";
import todoGuide from "./components/todoGuide";
import { APP_AUTH_KEY, APP_USER_SOURCE } from "@/config/constant";
import Cookies from "js-cookie";
import LoginDialog from "@/components/LoginDialog";

export default {
  data() {
    return {
      activeSerTab: 0,
      swiperNewsList: [],
      todoList: {},
      data: [],
      FairData: [],
      // 临时方案 分组
      isShowGroup: false,
      todoHotGroup: [], //组
      crtTodoHotGroup: [], //组内列表
      bannerList: [],

      loginDialogShow: false,
    };
  },
  watch: {},
  components: { PolicyIcon, JobList, FairList, todoGuide, LoginDialog },
  computed: {
    ...mapState({
      isLogin: (state) => state.global.isLogin,
      userInfo: (state) => state.global.userInfo,
    }),
    newList() {
      const a = this.swiperNewsList.splice(0, 5);
      return a;
    },
  },
  filters: {
    formatCardId(value) {
      if (!value) return "---";
      const cardID =
        value.substr(0, 3) + "***********" + value.substr(14, value.length);
      return cardID;
    },

    formatTime(val) {
      let date = val.split(" ")[0];
      let dateWithoutYear = date.split("-");
      return `${dateWithoutYear[1]}-${dateWithoutYear[2]}`;
    },
  },
  mounted() {
    this.getSwiperNewList();
    this.getHotWorkList();
    this.getBannerList();
    this.getfairsLists();
    this.getJobList();

    if (!this.isLogin) {
      this.loginDialogShow = true;
    }
  },
  methods: {
    /* 招聘会列表 */
    getfairsLists() {
      const params = {
        apiName: "/recruit/jobfairs/list",
        paramMap: { pageNum: 1, pageSize: 2 },
        requestType: 1,
      };
      getfairsList(params).then((res) => {
        let { data, success, code } = res;
        if (success) {
          this.FairData = data.records;
        }
      });
    },
    /* 招聘列表 */
    getJobList() {
      const params = {
        apiName: "/recruit/job-info/getJobInfoList",
        paramMap: {
          pageSize: 6,
          pageNum: 1,
          cityName: "郑州市",
          proName: "河南省",
          districtName: "航空港区",
          lat: "34.52532",
          lng: "113.854726",
          type: 1, //根据地址从近到远
        },
        requestType: 1,
      };
      getIndexJobList(params).then((res) => {
        this.data = res.data.list;
      });
    },
    getSwiperNewList() {
      const params = {
        pageNum: 1,
        pageSize: 10,
      };
      getHomeNewList(params).then((res) => {
        this.swiperNewsList = res.data.records;
      });
    },
    getNewList() {
      const params = {
        pageNum: 1,
        pageSize: 5,
      };
      getHomeNewList(params).then((res) => {
        this.newList = res.data.records;
      });
    },
    navToDetail(e) {
      this.$router.push({
        path: `/newsDetail/${e.idCode}`,
      });
    },
    navToList() {
      this.$router.push({
        path: `/newsList`,
      });
    },
    navToOffice(item) {
      this.$router.push({
        path: `/todoGuide`,
      });
    },

    formatTypeText(val) {
      let str;
      switch (val) {
        case "0":
          str = "综合新闻";
          break;
        case "1":
          str = "人才政策";
          break;
        case "2":
          str = "公示公告";
          break;
        case "3":
          str = "公开招聘";
          break;
        default:
          break;
      }
      return str;
    },
    handleLogin() {
      this.$store.dispatch("global/authLogin");
    },
    /*
     *@des: 获取办事项列表
     */
    getWorkList() {
      getWorkList({
        pageNum: 1,
        pageSize: 1000,
        serviceObject: "1",
      }).then((res) => {
        let { success, data } = res;
        if (success) {
          this.todoList = data || {};
        }
      });
    },

    /*
     *@des: 获取热门办事项列表
     */
    getHotWorkList() {
      queryAllGroup().then((res) => {
        if (res.code == 200) {
          this.todoHotGroup = res.data;
        }
      });
    },

    getBannerList() {
      getPageList({
        pageNum: 1,
        pageSize: 9,
        bannerType: 1, //banner渠道0-PC 1-手机
        onlineType: 1, //启用状态：0-下线 1-上线
      }).then((res) => {
        this.bannerList = res.data.records;
      });
    },
    /*
     *@des: 导航至办事项详情页
     */
    navToTodoDetail({ itemId }) {
      this.$router.push({
        path: `/guideDetail`,
        query: {
          itemId,
        },
      });
    },
    // 组的概念。政策可以分组，点击组名，出现对应的政策
    toggleGroup(value) {
      if (1 == value.items.length && 2 == value.eject) {
        this.$router.push({
          path: `/guideDetail`,
          query: {
            itemId: value.items[0].itemId,
          },
        });
        return;
      }
      this.isShowGroup = true;
      this.crtTodoHotGroup = value.items;
    },
    navToApplyList() {
      this.$router.push("/applylist");
    },
    goUrl(item) {
      if (0 == item.clickType) {
        return;
      }

      if ("http://VUE_APP_HOUSE_URL" == item.targetUrl) {
        let housUrl = process.env.VUE_APP_HOUSE_URL;
        let token = Cookies.get(APP_AUTH_KEY) || "";
        window.location.href = `${housUrl}?token=${token}`;
      } else {
        window.location.href = item.targetUrl;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  background: #f0f5f7;
  overflow: auto;
}
.banner {
  background-image: url("./img/2.png");
  background-position: center -1rem;
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 100%;
  overflow: hidden;
  margin-bottom: 0.54rem;
  .banner-content {
    border-radius: 0.2rem;
    margin: 0.6rem auto 0;
    .swiper-item {
      padding: 0 0.3rem;
      height: 2.8rem;
      .img {
        height: 100%;
        width: 100%;
      }
    }
    .link {
      display: block;
      font-size: 0;
      border-radius: 0.2rem;
      overflow: hidden;
    }
  }
}
.main-content {
  padding: 0 0.3rem;
  .main-card {
    background: #ffffff;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
    border-radius: 0.2rem;
    margin-bottom: 0.3rem;
    overflow: hidden;
    padding: 0.3rem;
    .title {
      font-size: 0.4rem;
      font-weight: 600;
      color: #333333;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 0.3rem;
      border-bottom: 1px solid #f0f0f0;
    }
  }
}
.user-content {
  display: flex;
  .progress-link {
    width: 2.6rem;
    background: #3c37d2;
    font-size: 0.4rem;
    font-weight: 600;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.user-info {
  margin-right: 0.3rem;
  flex: 1;
  .col-info {
    .row-name {
      font-size: 0.34rem;
      line-height: 0.36rem;
      color: #333333;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .row-id {
      margin-top: 0.2rem;
      font-size: 0.28rem;
      line-height: 0.3rem;
      color: #000;
    }
    .rn-tag {
      margin-top: 0.1rem;
    }
  }
  .col-progress {
    width: 1.7rem;
    height: 0.6rem;
    padding-left: 0.2rem;
    background: linear-gradient(
      48deg,
      #3c37d2 0%,
      #5954d6 50%,
      #f94363 90%,
      #ff4451 100%
    );
    box-shadow: 0.01rem 0.01rem 0.03rem 0rem rgba(103, 103, 103, 0.5);
    border-radius: 0.3rem 0rem 0rem 0.3rem;
    transform: translate(0.3rem, 0rem);
    font-size: 0.3rem;
    line-height: 0.6rem;
    color: #ffffff;
  }
}
.auth-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .remark {
    width: 3.9rem;
    font-size: 0.26rem;
    font-weight: 400;
    color: #999999;
  }
  .go-auth {
    font-size: 0.26rem;
    font-weight: 400;
    color: #3c37d2;
  }
}
</style>
