<template>
  <section class="todo-guide">
    <div class="todo-list" v-for="item in themeList" :key="item.name">
      <div class="list-title">
        <span class="span-1"
          ><van-icon class="icon" name="friends" />{{ item.name }}</span
        >
        <span class="span-2">共计{{ item.num }}项服务</span>
      </div>
      <div class="list-content">
        <ServiceList :list="item.groups" :num="item.num" />
      </div>
    </div>
  </section>
</template>

<script>
import { queryAllClassify } from "@/api/policy/itemClassify";
import ServiceList from "./ServiceList.vue";

export default {
  name: "todo-guide",
  data() {
    return {
      //  办事项主题分类
      themeList: [],
      todoList: {},
      crtList: [],
    };
  },
  components: {
    ServiceList,
  },
  computed: {},
  created() {
    this.getWorkList();
  },
  methods: {
    /*
     *@des: 获取办事项列表
     */
    getWorkList() {
      queryAllClassify().then((res) => {
        this.themeList = res.data;
      });
    },
    /*
     *@des: 导航至办事项详情页
     */
    navToTodoDetail({ itemId }) {
      this.$router.push({
        path: `/guideDetail`,
        query: {
          itemId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.todo-guide {
  background-color: #f8f8f8;
  .real-auth {
    padding: 0.12rem 0rem 0.4rem 0.12rem;
    display: flex;
    justify-content: space-between;
    span {
      font-size: 0.26rem;
      line-height: 0.37rem;
      color: #999;
    }
    span.nav {
      color: #3c37d2;
    }
  }
  .todo-list {
    background: #ffffff;
    border-radius: 0.08rem;
    padding: 0.3rem 0;
    .list-title {
      padding-bottom: 0.3rem;
      .span-1 {
        display: inline-block;
        height: 0.36rem;
        padding: 0rem 0.16rem;
        border-right: 0.01rem solid #e5e5e5;
        font-size: 0.34rem;
        line-height: 0.36rem;
        font-weight: bold;
        color: #000;
        .icon {
          margin-right: 0.1rem;
          color: #3e39d2;
          font-size: 0.36rem;
        }
      }
      .span-2 {
        margin-left: 0.16rem;
        font-size: 0.22rem;
        color: #999999;
        line-height: 0.36rem;
      }
    }
  }
}
</style>
