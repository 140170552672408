import service from "@/utils/request";
/**
 * @description: 首页新闻列表
 */
export function getHomeNewList(params) {
  return service({
    url: "/policy/news/H5/getNewsList",
    params,
  });
}
/**
 * @description:  新闻列表
 */
export function getNewListByType(params) {
  return service({
    url: "/policy/news/H5/getNewsList",
    params,
  });
}
/**
 * @description:  新闻详情
 */
export function getNewsDetail(params) {
  return service({
    url: "/policy/news/getPolicyNewsDetail",
    params,
  });
}

/**
 * @description:  办事项列表（分类）
 */
export function getWorkList(data) {
  return service({
    url: "/policy/item/queryGroupByThemeTagH5",
    method: "POST",
    data,
  });
}

/**
 * @description:  热门办事项列表
 */
export function getHotWorkList(params) {
  return service({
    url: "/policy/item/H5/getHot",
    method: "get",
    params,
  });
}

/**
 * @description:  批量获取新闻浏览量
 */
export function queryNewsPV(params) {
  return service({
    url: "/policy/news/queryNewsPV",
    method: "get",
    params,
  });
}
