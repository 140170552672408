import request from "@/utils/request";

/* 查询全部分类、分组及政策 */
export function queryAllClassify(params) {
  return request({
    url: "/policy/item-classify/h5/allClassify",
    method: "GET",
    params
  });
}

/* 查询全部热门分组及政策 */
export function queryAllGroup(params) {
  return request({
    url: "/policy/item-classify/h5/allGroup",
    method: "GET",
    params
  });
}