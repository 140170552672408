import request from "@/utils/request";

// 添加轮播图
export function addBanner(data) {
  return request({
    url: "/policy/banner/addBanner",
    method: "post",
    data: data,
  });
}
// 删除轮播图
export function delBanner(data) {
  return request({
    url: `/policy/banner/del/${data.bizCode}`,
    method: "DELETE",
  });
}
// 修改轮播图
export function eidtBanner(data) {
  return request({
    url: "/policy/banner/eidtBanner",
    method: "post",
    data: data,
  });
}

// 轮播图分页列表查询
export function getPageList(params) {
  return request({
    url: "/policy/banner/pageList",
    method: "get",
    params: params,
  });
}