var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.num > 0)?_c('div',{staticClass:"service-list"},[_vm._l((_vm.list),function(item,index){return [(item.items && item.items.length)?_c('div',{key:index,staticClass:"service-item",on:{"click":function($event){return _vm.hanldeClick(item)}}},[_c('div',{staticClass:"service-icon"},[_c('PolicyIcon',{attrs:{"icon":item.items[0].iconUrl}})],1),_c('div',{staticClass:"service-title"},[_vm._v(_vm._s(item.name))]),(
            1 == item.serviceObject &&
            1 == item.itemStatus &&
            1 == item.online
          )?_c('div',{staticClass:"point"},[_vm._v(" 在办 ")]):_vm._e()]):_vm._e()]})],2):_c('van-empty',{attrs:{"image-size":"100","description":"暂无数据"}}),_c('van-popup',{style:({ height: '30%' }),attrs:{"position":"bottom"},model:{value:(_vm.isShowGroup),callback:function ($$v) {_vm.isShowGroup=$$v},expression:"isShowGroup"}},[_c('div',{staticStyle:{"padding":"0.2rem"}},[_c('div',{staticClass:"service-list"},[_vm._l((_vm.groupList),function(item,index){return [_c('div',{key:index,staticClass:"service-item",on:{"click":function($event){return _vm.navToTodoDetail(item)}}},[_c('div',{staticClass:"service-icon"},[_c('PolicyIcon',{attrs:{"icon":item.iconUrl}})],1),_c('div',{staticClass:"service-title"},[_vm._v(" "+_vm._s(item.itemAlias || item.itemName)+" ")]),(
                item.serviceObject.indexOf('1') > -1 &&
                1 == item.itemStatus &&
                1 == item.online
              )?_c('div',{staticClass:"point"},[_vm._v(" 在办 ")]):_vm._e()])]})],2)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }